
import { featureset } from '@/plugins/smd-feature';
import { defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

type Zusammenarbeit = {
  userid: number;
  text: string;
  note: string;
};

export default defineComponent({
  props: {
    user: { type: Number, required: true },
  },
  setup(props) {
    const zusammenarbeiten = ref<Array<Zusammenarbeit>>([]);
    const store = useStore();

    onMounted(() => {
      if (!featureset['projektzusammenarbeit']) return;

      store.dispatch('fetchZusammenarbeit', props.user).then((res) => {
        zusammenarbeiten.value = res;
      });
    });

    return {
      zusammenarbeiten,
    };
  },
});
